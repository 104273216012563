// footer {
//
//     padding-bottom: 30px;
//     background-color: #fff;
//     padding-top: 30px;
//     .center {
//       display: flex;;
//       flex-wrap: wrap;
//       justify-content: center;
//       @media(max-width: 786px) {
//         justify-content: center;
//       }
//     }
//     span,a {
//       color: $dark;
//       margin-left: 20px;
//       margin-right: 20px;
//       font-family: 'Open Sans', sans-serif;
//     }
//     a {
//       @media(max-width: 786px) {
//         margin-bottom: 12px;
//       }
//     }
// }

.subpage {
  color: #1A385F;
}

.kontakt.subpage, .termin-buchen.subpage {
  .mainDiv.content {
    width: 900px;
    font-size: 23px;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: calc(100vw - 40px);

  }
  h2 {
    margin-top: 15px;margin-bottom: 15px;
  }
  p {
    line-height: 20px;
    color: #343434;
    font-size: 16px;
    margin-bottom: 15px;
    @media(max-width: 786px) {
      font-size: 16px;
      line-height: 23px;
    }
  }
  b {
    color: #343434;
  }
}

.subpage {
  .navbar {
    justify-content: center;
  }
}

.termin-buchen.subpage {
  h1 {
    margin-top: 0px;
  }
}


.popup-vimeo {
  position: absolute;
top: 50%;
left: 50%;
z-index: 10;
}
:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}

.animate__animated.animate__infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

.animate__pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}
.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    opacity: 0;
}

@keyframes pulse {
    0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
50% {
    -webkit-transform: scale3d(2, 2, 2);
    transform: scale3d(1.2, 1.2, 1.2);
}
100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
}
}



@-webkit-keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes backInUp {
  0% {
    -webkit-transform: translateY(1200px) scale(0.7);
    transform: translateY(1200px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    -webkit-transform: translateY(0px) scale(0.7);
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.animate__backInUp {
  -webkit-animation-name: backInUp;
  animation-name: backInUp;
}


@-webkit-keyframes wobble {
  2% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);}
  4% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);}
  6% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  8% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
  10% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  12% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  14% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
  }
  16% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
  }
  18% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
  }
  20% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
  }
}
@keyframes wobble {
  2% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);}
4% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);}
6% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
}
8% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
}
10% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
}
12% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
}
14% {
    -webkit-transform: translateX(2px) rotate(1deg);
    transform: translateX(2px) rotate(1deg);
}
16% {
    -webkit-transform: translateX(-2px) rotate(-1deg);
    transform: translateX(-2px) rotate(-1deg);
}
18% {
    -webkit-transform: translateX(1px) rotate(0);
    transform: translateX(1px) rotate(0);
}
20% {
    -webkit-transform: translateX(-1px) rotate(0);
    transform: translateX(-1px) rotate(0);
}
}
.animate__wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
  opacity: 1;
  animation-duration: 4s;
}
