@import "standard";
@import "owl.carousel.min";
@import "owl.theme.default.min";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap");

@font-face {
  font-family: "proxima_nova_rgregular";
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova_rgbold";
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  list-style: none;
  outline: none;
  text-decoration: none;
  box-sizing: border-box;
}

body {
  font-family: "proxima_nova_rgregular", Helvetica, Arial, sans-serif;
  font-size: 16px;
  color: rgba(78, 78, 78, 0.8);
}
.container {
  width: 1170px;
  max-width: 95%;
  margin: 0 auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4e4e4e;
  font-family: "proxima_nova_rgbold", Helvetica, Arial, sans-serif;
}
.inerpage {
  padding: 50px 0;
}
.inerpage p {
  line-height: 24px;
  margin-bottom: 20px;
}
.inerpage b {
  margin-bottom: 10px;
  display: inline-block;
}

.header-sec {
  padding: 18px 0 18px;
  position: relative;
  z-index: 1;
  background: #fff;
}

.header-sec .wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0 auto;
}
.header-sec .burger {
  position: relative;
  display: none;
  cursor: pointer;
  width: 2rem;
  height: 1rem;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
  background: none;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}
.header-sec .burger-line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  left: 0;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 0.25rem;
  background: #00ff99;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.header-sec .burger-line:nth-child(1) {
  top: 0;
}
.header-sec .burger-line:nth-child(2),
.header-sec .burger-line:nth-child(3) {
  top: 0.5rem;
}
.header-sec .burger-line:nth-child(4) {
  top: 1rem;
}
.header-sec .burger.active .burger-line:nth-child(1),
.header-sec .burger.active .burger-line:nth-child(4) {
  top: 1.25rem;
  width: 0%;
  left: 50%;
}
.header-sec .burger.active .burger-line:nth-child(2) {
  transform: rotate(45deg);
}
.header-sec .burger.active .burger-line:nth-child(3) {
  transform: rotate(-45deg);
}
.header-sec .menu-inner {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  margin-right: 0;
}
.header-sec .menu-link {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border: none;
  transition: all 0.35s ease-in-out;
  padding: 0 0;
}
.header-sec .menu-link.active,
.header-sec .menu-link:hover {
  border-bottom: 2px solid #00ff99;
  outline: none;
  color: #000;
}

.social-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.d-mob-block {
  display: none !important;
}

@media only screen and (max-width: 910px) {
  .header-sec .burger {
    display: block;
    opacity: 1;
    visibility: visible;
  }
  .header-sec .menu {
    width: 100%;
    max-height: 0rem;
    padding: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.35s ease;
  }
  .header-sec .menu.active {
    opacity: 1;
    visibility: visible;
  }
  .header-sec .menu-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 20px !important;
    margin-right: 0;
    position: absolute;
    top: 75%;
    background: #000 !important;
  }
  .header-sec .menu-link {
    color: #fff !important;
    padding: 5px;
  }
}
.img-fluid {
  max-width: 100%;
}
.w-100 {
  width: 100%;
}
.hero-main {
  position: relative;
  display: inline-block;
  width: 100%;
  background: url(../img/hero-bg.png) no-repeat center top;
  background-size: 100% 900px;
}
.hero-inr {
  padding: 100px 0 0;
}
.text-center {
  text-align: center !important;
}
.hero-main p {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  margin-bottom: 20px;
}
.hero-main h1 {
  font-weight: bold;
  font-size: 44px;
  line-height: 52px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 30px;
}
.container-middle {
  display: flex;
  justify-content: center;
}
.w-80 {
  width: 80%;
}

.btn-all {
  transition: transform 0.2s;
  background: #00ff99;
  padding: 18px 36px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}
.btn-all:hover {
  transform: scale(1.1);
}
.mb-80 {
  margin-bottom: 80px;
}

.middle-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-box {
  display: flex;
  align-items: center;
  background: #fff;
  width: 32%;
}
.icon-box span {
  width: 64px;
  height: 64px;
  background: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-box div {
  padding: 10px 15px;
  text-align: left;
  width: 70%;
  font-weight: normal;
  font-size: 16px;
  color: #000000;
  font-family: "proxima_nova_rgregular";
}

.video-pnl {
  border: 20px solid #fff;
  background: #fff;
  display: inline-block;
}
.video-pnl iframe {
  width: 100%;
}
.video-pnl img {
  width: 100%;
}
.w-90 {
  width: 90%;
}

.video-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.struggling {
  padding: 100px 0 100px;
  display: inline-block;
  width: 100%;
}

.d-flex {
  display: flex;
}
.in_between {
  justify-content: space-between;
}

.picture-section {
  padding: 20px;
  box-shadow: 0px 12px 20px rgba(47, 49, 55, 0.08);
  background: #fff;
  width: 560px;
}

.grip-on-your {
  width: 460px;
  padding-left: 30px;
}
.grip-on-your p {
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  margin-bottom: 15px;
  color: #000000;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.common-h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  position: relative;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 40px;
  padding-top: 20px;
}
.common-h2::after {
  position: absolute;
  left: 0;
  background: #00ff99;
  height: 4px;
  width: 100px;
  top: 0;
  content: "";
}

.three-piller {
  padding: 55px 0 0 0;
  background: #f3f4f5;
}
.center-h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  position: relative;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 24px;
  padding-top: 20px;
  text-align: center;
}
.center-h2::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #00ff99;
  height: 4px;
  width: 100px;
  top: 0;
  content: "";
}
.center-h3 {
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  position: relative;
  letter-spacing: -0.02em;
  color: #000000;
  margin-bottom: 10px;
  padding-top: 10px;
  text-align: center;
}
.center-h3::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #00ff99;
  height: 4px;
  width: 100px;
  top: 0;
  content: "";
}
.sm-pra {
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  color: #000000;
}
.mt-80 {
  margin-top: 80px;
}

.pillar {
  width: 508px;
}
.white-pnl {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 15px;
}
.white-pnl2 {
  background: #ffffff;
  padding: 20px;
  margin-bottom: 40px;
}
.white-pnl h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  margin-bottom: 15px;
  color: #000000;
}
.white-pnl p {
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #000000;
}
.w-500 {
  width: 500px;
}
.w-500 img {
  display: inline-block;
  margin-bottom: -5px;
}
.refocus {
  padding-bottom: 120px;
}
.refocus .in_between {
  align-items: center;
}
.refocus .grip-on-your {
  width: 520px;
}
.refocus .grip-on-your p {
  padding-right: 50px;
}
.refocus h2 span {
  color: #00ff99;
}

.its {
  background: #f3f4f5 url(../img/beijing.svg) no-repeat bottom left;
}

.now-possible {
  width: 438px;
  padding-top: 80px;
}

.now-possible p {
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  text-transform: uppercase;
  position: relative;
  color: #000000;
  margin-bottom: 20px;
  padding-top: 20px;
}
.now-possible p::after {
  position: absolute;
  left: 0;
  background: #00ff99;
  height: 4px;
  width: 100px;
  top: 0;
  content: "";
}
.now-possible h3 {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;

  letter-spacing: -0.02em;
  margin-bottom: 40px;

  color: #000000;
}
.row-flex {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
}
.row-flex div {
  width: 50%;
  padding: 20px;
  min-height: 250px;
}
.row-flex div h4 {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 15px;
}
.row-flex div p {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.row-flex div.odd {
  background: #000000;
}
.row-flex div.odd h4,
.row-flex div.odd p {
  color: #fff !important;
}

.how-do-we {
  background: #f3f4f5;
  padding: 80px 0;
}
.grid-all {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}
.grid-5 {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 15px 0;
}
.grid-7 {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 15px 0;
}
.grid-4 {
  width: 100%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  padding-right: 15px;
  padding-left: 15px;
  margin: 15px 0;
}

.item-box {
  padding: 32px;
  background: #fff;
  height: 100%;
}
.item-box h2 {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: -0.02em;
  color: #00ff99;
  margin-bottom: 30px;
}

.item-box h3 {
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  margin-bottom: 15px;
  color: #000000;
}
.item-box p {
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #000000;
}

.right-pp {
  position: absolute;
  right: -40px;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.item-box {
  position: relative;
}

.img-fluid {
  max-width: 100%;
}
.ddhy {
  position: relative;
  margin-top: -20px;
  margin-bottom: -20px;
}
.w-70 {
  width: 65%;
}
.btn-dd {
  width: 350px;
}
.align-top {
  align-items: flex-start;
  padding-top: 30px;
}

.align-top p {
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #000000;
}

.testimonial_sec {
  padding: 80px 0;
  background: url(../img/bg2.png) no-repeat center top;
  background-size: cover;
}

.center-h2-black {
  font-weight: bold;
  font-size: 36px;
  line-height: 40px;
  position: relative;
  letter-spacing: -0.02em;
  color: #fff;
  margin-bottom: 24px;
  padding-top: 20px;
  text-align: center;
}
.center-h2-black::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background: #00ff99;
  height: 4px;
  width: 100px;
  top: 0;
  content: "";
}

.content-center {
  justify-content: center;
  padding: 50px 25%;
}
.owl-theme .item {
  text-align: center;
}
.owl-theme .item img {
  max-width: 120px;
  margin: 0 auto 30px;
}
.owl-theme .item p {
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
}

.owl-theme .item h4 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  text-align: center;

  color: #ffffff;
}
.owl-theme .owl-nav {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  margin-top: 0;
  transform: translateY(-50%);
}

.owl-theme .owl-nav .owl-prev {
  position: absolute;
  left: -80px;
  margin: 0;
  color: #fff !important;
  opacity: 1 !important;
  font-size: 40px !important;
  background: transparent !important;
}

.owl-theme .owl-dots {
  display: none;
}
.owl-theme .owl-nav .owl-next {
  position: absolute;
  right: -80px;
  margin: 0;
  color: #fff !important;
  opacity: 1 !important;
  font-size: 40px !important;
  background: transparent !important;
}

footer {
  padding: 80px 0;
}

footer .footer-top {
  border-bottom: 1px solid #cbcccb;
  padding: 0 0 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-footer-logo {
  max-width: 150px;
}
footer .footer-top ul li {
  display: inline-block;
  margin-left: 40px;
}

footer .footer-top ul li a {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  color: #000000;
}
footer .footer-top ul li a:hover {
  text-decoration: underline;
}

.footer-btm {
  padding: 40px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-btm p {
  font-weight: normal;
  font-size: 14px;
  line-height: 170%;

  color: #000000;
}
.footer-btm ul li {
  margin-left: 12px;
  display: inline-block;
}
.footer-btm ul li:hover {
  opacity: 0.6;
}

.testi-logo img {
  width: 200px !important;
}

.white-bg {
  padding: 10px;
  background: #fff;
}

// responsiveness

@media only screen and (min-width: 1024px) and (max-width: 1366px) {
  .header-sec .menu-inner {
    margin-right: 0;
  }
  .header-sec .menu-inner {
    gap: 3rem;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1180px) {
  .video-pnl iframe {
    height: 381px;
  }
  .header-sec .menu-inner {
    gap: 1rem;
    // background: transparent !important;
  }
  .header-sec .menu-inner {
    // padding: 0 !important;
  }
  .icon-box div {
    width: 96%;
  }
  // .header-sec .menu-link {
  //   color: #000000 !important;
  // }
}

@media only screen and (max-width: 1023px) {
  .video-pnl iframe {
    height: 353px;
  }
  .header-sec .menu-inner {
    margin-right: 0;
  }
  .icon-box div {
    font-size: 12px;
  }
  .header-sec .menu-link.active,
  .header-sec .menu-link:hover {
    color: #000;
  }
  .header-sec .menu-link {
    color: #000;
  }
  .center-h2 {
    font-size: 34px;
  }
  .pillar {
    width: 50%;
  }
  .common-h2 {
    font-size: 34px;
  }
  .now-possible h3 {
    font-size: 30px;
  }
  .btn-all {
    padding: 15px 15px;
  }
  .content-center {
    padding: 50px 15%;
  }
  .w-500 {
    width: 50%;
    padding-left: 30px;
  }
  .header-sec .menu-inner {
    background: #fff;
    padding: 20px;
    right: 0;
  }
  .hero-main h1 br {
    display: none;
  }
  .w-80 {
    width: 100%;
  }
  .in_between {
    align-items: flex-start;
  }
  .picture-section {
    width: 40%;
  }
  .grip-on-your {
    width: 60%;
    padding-left: 40px;
  }
  footer .footer-top ul li {
    margin-left: 15px;
  }
  .its {
    background: #f3f4f5 url(../img/beijing.svg) no-repeat bottom left -250px;
  }
  .btn-dd {
    width: 280px;
    font-size: 14px;
  }
  .w-70 {
    width: 52%;
  }
  .three-piller {
    padding-bottom: 55px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-main {
    background-size: cover;
    padding-top: 0;
  }
  .hero-inr {
    padding-top: 50px;
  }
  .hero-main h1 {
    font-size: 30px;
    line-height: normal;
  }
  .middle-flex {
    display: inline-block;
    width: 100%;
  }
  .icon-box {
    width: 100%;
    margin-bottom: 10px;
  }
  .mb-80 {
    margin-bottom: 30px;
  }
  .w-90 {
    width: 100%;
  }
  .struggling {
    padding: 10px 0;
  }
  .d-flex {
    display: inline-block;
    width: 100%;
  }
  .picture-section {
    width: 100%;
    margin-bottom: 20px;
  }
  .grip-on-your {
    width: 100%;
    padding: 0;
  }
  .pillar {
    width: 100%;
  }
  .w-500 {
    width: 100%;
    padding: 20px 0 0 0;
  }
  .refocus .grip-on-your {
    width: 100%;
    padding-top: 50px;
  }
  .refocus {
    padding-bottom: 50px;
  }
  .now-possible {
    width: 100%;
    padding-bottom: 30px;
  }
  .row-flex {
    width: 100%;
  }
  .its {
    background: #f3f4f5;
  }
  .center-h2 br {
    display: none;
  }
  .ddhy {
    margin: 0;
  }
  .ddhy img {
    display: none;
  }
  .right-pp {
    display: none;
  }

  .grid-5 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  .grid-7 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  .grid-4 {
    width: 100%;
    max-width: 100%;
    flex: 100%;
  }
  .w-70 {
    width: 100%;
  }
  .owl-theme .owl-nav .owl-prev {
    left: -40px;
  }
  .owl-theme .owl-nav .owl-next {
    right: -40px;
  }
  footer {
    padding: 50px 0;
  }
  footer .footer-top {
    text-align: center;
    width: 100%;
    display: inline-block;
    padding-bottom: 20px;
  }
  footer .footer-top ul {
    padding-top: 15px;
  }
  footer .footer-top ul li {
    margin: 0 10px;
  }
  .footer-btm {
    padding-top: 20px;
    text-align: center;
    width: 100%;
    display: inline-block;
  }
  .footer-btm ul {
    padding: 15px 0 0 0;
  }
  .footer-btm ul li {
    margin: 0 10px;
  }
  .grid-all {
    margin: 0;
  }
  .how-do-we {
    padding: 50px 0;
  }
  .testimonial_sec {
    padding: 50px 0;
  }
  .btn-dd {
    width: 100%;
  }
  h1.brand img {
    width: 150px;
  }
  .refocus .grip-on-your p {
    padding-right: 0;
  }
  .align-top p {
    margin-bottom: 15px;
  }
  .center-h2-black {
    font-size: 30px;
  }
  .owl-theme .item p {
    font-size: 18px;
    line-height: 24px;
  }
  .d-mob-nan {
    display: none;
  }
  .d-mob-block {
    display: block !important;
    margin: 50px 0 50px;
  }
  .three-piller {
    padding-bottom: 0;
  }
  .row-flex div {
    padding: 15px;
  }
  .video-pnl iframe {
    height: 188px;
  }
  .btn-fix-mobile {
    margin-top: 20px;
  }
  .btn-fix-mobile2 {
    margin-top: 10px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 320px) {
  .video-pnl iframe {
    height: 150px;
  }
}

// styling for hubspot form

.form-styling {
  padding: 30px;
  background-color: rgba(255, 255, 255, 0.95);
}

.section-styling {
  padding: 20px;
}

#fb-icon {
  color: #3b5998;
}

#li-icon {
  color: #0e76a8;
}

.post-box {
  width: 460px;
}
.post-box p {
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  margin-bottom: 15px;
  color: #000000;
}

.refocus .post-box {
  width: 520px;
}

.refocus .post-box p {
  padding-right: 50px;
}

.post-box {
  width: 60%;
  padding-left: 40px;
}

.post-box {
  width: 100%;
  padding: 0;
}

.refocus .post-box {
  width: 100%;
  padding-top: 50px;
}

.refocus .post-box p {
  padding-right: 0;
}

.section-styling2 {
  padding: 100px 0 100px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.form-styling2 {
  margin: 0;
  width: 90%;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 30px;
}

.search {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.softbox {
  text-align: center;
  transition: border 0.2s linear;
  font-size: 18px;
  width: 50%;
  background: #f5f8fa;
  color: #000;
  padding: 8px 32px 8px 12px;
  height: 44px;
  border: 1px solid #e6ecf0;
  outline: none;
}

.softbox:focus {
  transition: border 0.2s linear;
  border: 2px solid #00ff99;
}

.search-btn {
  border: none;
  transition: transform 0.2s;
  background: #00ff99;
  padding: 18px 36px;
  text-align: center;
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  color: #000000;
  cursor: pointer;
}

.search-btn:hover {
  transform: scale(1.1);
}

.search-btn {
  padding: 15px 15px;
}

.bg-fix {
  background: #f3f4f5;
}

.image-grid {
  justify-content: center;
  align-items: center;
  display: grid;
  grid-template-columns: repeat(3, 150px);
  grid-gap: 50px;
}
.image-grid img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}